import React, { useState, useRef, useContext, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Icon from 'react-feather';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import Bugsnag from '@bugsnag/js';

import { useMutation } from '@apollo/react-hooks';
import { BooksContext } from '../../services/books/BooksProvider';
import { AuthContext } from '../../services/auth/AuthProvider';
import { ADD_READING_LOG, MARK_AS_COMPLETE, ADD_GROUP_LOG, MARK_GB_AS_COMPLETE } from './services/graphql';
import { GET_STUDENT_QUERY } from '../../scenes/Student/services/graphql';
import {GET_GROUPS} from '../../scenes/ClassNew/components/Groups/services/graphql'
import {GET_CLASS_STATS_QUERY} from '../../scenes/ClassNew/services/graphql'
import styles from './LogReading.module.scss';


const LogNew = ( {student, book, recentLog, success, close, complete = false, toggleComplete, initiateComplete = false, initiateMethod, type, classId, filter, singleGroupLog = false}) => {
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const bookContext = useContext(BooksContext)
    const { books, setBooks, logs, setLogs, activityFeed, setActivityFeed, classActivityFeed, setClassActivityFeed } = bookContext;

    const [formErrors, setFormErrors] = useState([]);

    const [selectedStudents, setSelectedStudents] = useState([])
    const [expandPupils, setExpandPupils] = useState(false)
    const [justNow, setJustnow] = useState(true)

    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });

    const [record, setRecord] = useState({
        date: new Date().toDateInputValue(),
        time: new Date().toTimeString().slice(0,5),
        pageStart: recentLog || 1,
        pageEnd: '',
        comment: "",
        problemWords: [],
        completed: false,
        showError: false,
    })

    useEffect(() => {
        if (justNow) {
            setRecord({...record,
                date: new Date().toDateInputValue(),
                time: new Date().toTimeString().slice(0,5),
            })
        }
    }, [justNow])

    useEffect(() => {
        if (book.students && book.students.length > 0) {
           setSelectedStudents(getStudentIds(book.students)) 
        }
    }, [])

    const tagInput = useRef(null);
    
    const addWordTags = e => {
        const val = e.target.value;
        if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
            e.preventDefault();
            if (record.problemWords.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                return;
            }
            setRecord({
                ...record,
                problemWords: [...record.problemWords, val.trim()]
            });
            tagInput.current.value = null;
        }
    };

    const removeWordTag = i => {
        const newWords = [ ...record.problemWords ];
        newWords.splice(i, 1);
        setRecord({
            ...record,
            problemWords: newWords
        });
    };

    const [addLog, { loading }] = useMutation(ADD_READING_LOG, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            console.log("completed")
            Bugsnag.leaveBreadcrumb('book data received', data, 'state');
            if (data && data.addReadingBookLog && data.addReadingBookLog.errors && data.addReadingBookLog.errors.length > 0) {
                setFormErrors(data.addReadingBookLog.errors);
                return;
            }
            if (data && data.addReadingBookLog && data.addReadingBookLog.studentReadingBook && data.addReadingBookLog.studentReadingBook.id) {
                success(true);
                const updatedBook = data.addReadingBookLog.studentReadingBook
                const newBooks = []
                books.forEach(book => {
                    if (book.id === updatedBook.id) {
                    newBooks.push(updatedBook)
                    } else {
                    newBooks.push(book)
                    }
                })
                const newLogs = logs;
                newLogs.push(data.addReadingBookLog.studentReadingBook.mostRecentLog);
                setBooks(newBooks)
                setLogs(newLogs);
                const newFeed = activityFeed;
                newFeed.push({
                    id: Date.now(),
                    activityDate: data.addReadingBookLog.studentReadingBook.mostRecentLog.readingDate,
                    activityType: 1,
                    author: data.addReadingBookLog.studentReadingBook.mostRecentLog.author.firstName + ' ' + data.addReadingBookLog.studentReadingBook.mostRecentLog.author.lastName,
                    bookName: data.addReadingBookLog.studentReadingBook.book.title,
                    bookType: data.addReadingBookLog.studentReadingBook.bookType,
                    fromPage: data.addReadingBookLog.studentReadingBook.mostRecentLog.pageStart,
                    rankName: null,
                    reviewRate: null,
                    reviewText: null,
                    logComment: data.addReadingBookLog.studentReadingBook.mostRecentLog.entry,
                    toPage: data.addReadingBookLog.studentReadingBook.mostRecentLog.pageEnd,
                })
                setActivityFeed(newFeed);
            }
        },
        onError: (e) => {
            Bugsnag.notify(e)
        },
        refetchQueries: () => [
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: student.id
                }
            }
        ]
    });

    const [addGroupLog, {loading: groupLoading}] = useMutation(ADD_GROUP_LOG, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data && data.addGroupReadingBookLog && data.addGroupReadingBookLog.errors && data.addGroupReadingBookLog.errors.length > 0) {
                setFormErrors(data.addGroupReadingBookLog.errors);
                return;
            }
            if (data && data.addGroupReadingBookLog && data.addGroupReadingBookLog.groupReadingBook && data.addGroupReadingBookLog.groupReadingBook.id) {
                success(true);
                if (singleGroupLog) {
                    const updatedBook = data.addGroupReadingBookLog.groupReadingBook
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id === updatedBook.id) {
                        newBooks.push(updatedBook)
                        } else {
                        newBooks.push(book)
                        }
                    })
                    const newLogs = logs;
                    newLogs.push(data.addGroupReadingBookLog.groupReadingBook.mostRecentLog);
                    setBooks(newBooks)

                    const newFeed = activityFeed;
                    newFeed.push({
                        id: Date.now(),
                        activityDate: record.date,
                        activityType: 4,
                        author: teacher.firstName + ' ' + teacher.lastName,
                        bookName: data.addGroupReadingBookLog.groupReadingBook.book.title,
                        bookType: data.addGroupReadingBookLog.groupReadingBook.bookType,
                        fromPage: record.pageStart,
                        rankName: null,
                        reviewRate: null,
                        reviewText: null,
                        toPage: record.pageEnd,
                    })
                    setActivityFeed(newFeed);
                }
                const newClassFeed = classActivityFeed;
                if (data.addGroupReadingBookLog.groupReadingBook.mostRecentLog) {
                    newClassFeed.unshift({
                        id: Date.now(),
                        activityDate: data.addGroupReadingBookLog.groupReadingBook.mostRecentLog.date,
                        activityType: 13,
                        author: teacher.firstName + ' ' + teacher.lastName,
                        bookName: data.addGroupReadingBookLog.groupReadingBook.book.title,
                        bookType: data.addGroupReadingBookLog.groupReadingBook.bookType,
                        fromPage: record.pageStart,
                        rankName: null,
                        reviewRate: null,
                        reviewText: null,
                        toPage: +record.pageEnd,
                        logComment: data.addGroupReadingBookLog.groupReadingBook.mostRecentLog.entry,
                        students: data.addGroupReadingBookLog.groupReadingBook.students.map(s => s.firstName + ' ' + s.lastName),
                        groupReadingBookLogId: data.addGroupReadingBookLog.groupReadingBook.mostRecentLog.id
                    })
                    setClassActivityFeed(newClassFeed)
                }
            }
        },
        refetchQueries: () => [
            !singleGroupLog ? (
            {
                query: GET_GROUPS,
                variables: {
                    id: classId,
                    status: filter,
                }
            }, {
                query: GET_CLASS_STATS_QUERY,
                variables: {
                    id: classId,
                }
            }) :
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: student.id
                }
            }
        ]
    })

    const [completeLog, { loading: completeLoading}] = useMutation(MARK_AS_COMPLETE, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data && data.markReadingBookAsCompleted && data.markReadingBookAsCompleted.errors && data.markReadingBookAsCompleted.errors.length >0) {
                setFormErrors(data.markReadingBookAsCompleted.errors);
                return;
            }
            if (data && data.markReadingBookAsCompleted.studentReadingBook && data.markReadingBookAsCompleted.studentReadingBook.mostRecentLog) {
                success(true)
                const newFeed = activityFeed;
                newFeed.push({
                    id: Date.now(),
                    activityDate: data.markReadingBookAsCompleted.studentReadingBook.mostRecentLog.readingDate,
                    activityType: 9,
                    author: data.markReadingBookAsCompleted.studentReadingBook.mostRecentLog.author.firstName + ' ' + data.markReadingBookAsCompleted.studentReadingBook.mostRecentLog.author.lastName,
                    bookName: data.markReadingBookAsCompleted.studentReadingBook.book.title,
                    bookType: data.markReadingBookAsCompleted.studentReadingBook.bookType,
                    fromPage: data.markReadingBookAsCompleted.studentReadingBook.mostRecentLog.pageStart,
                    rankName: null,
                    reviewRate: null,
                    reviewText: null,
                    toPage: data.markReadingBookAsCompleted.studentReadingBook.mostRecentLog.pageEnd,
                })
                setActivityFeed(newFeed);
                const updatedBook = data.markReadingBookAsCompleted.studentReadingBook
                const newBooks = []
                books.forEach(book => {
                    if (book.id === updatedBook.id) {
                    newBooks.push(updatedBook)
                    } else {
                    newBooks.push(book)
                    }
                })
                setBooks(newBooks)
            }
        },
        onError: (error) => {
            console.log(error)
        },
        refetchQueries: () => [
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: student.id
                }
            }
        ]
    })

    const [completeGBLog, { loading: completeGBLoading}] = useMutation(MARK_GB_AS_COMPLETE, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data && data.markGroupReadingBookAsCompleted && data.markGroupReadingBookAsCompleted.errors && data.markGroupReadingBookAsCompleted.errors.length > 0) {
                setFormErrors(data.markGroupReadingBookAsCompleted.errors);
            }
            if (data && data.markGroupReadingBookAsCompleted && data.markGroupReadingBookAsCompleted.groupReadingBook && data.markGroupReadingBookAsCompleted.groupReadingBook.id) {
                success(true);
                if (singleGroupLog) {
                    const updatedBook = data.markGroupReadingBookAsCompleted.groupReadingBook
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id === updatedBook.id) {
                        newBooks.push(updatedBook)
                        } else {
                        newBooks.push(book)
                        }
                    })
                    const newFeed = activityFeed;
                    newFeed.push({
                        id: Date.now(),
                        activityDate: record.date,
                        activityType: 9,
                        author: teacher.firstName + ' ' + teacher.lastName,
                        bookName: data.markGroupReadingBookAsCompleted.groupReadingBook.book.title,
                        bookType: data.markGroupReadingBookAsCompleted.groupReadingBook.bookType,
                        fromPage: record.pageStart,
                        rankName: null,
                        reviewRate: null,
                        reviewText: null,
                        toPage: record.pageEnd,
                    })
                    setActivityFeed(newFeed);
                }
            }
        },
        refetchQueries: () => [
            !singleGroupLog ?
            {
                query: GET_GROUPS,
                variables: {
                    id: classId,
                    status: filter,
                }
            } :
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: student.id
                }
            }
        ]
    })

    useEffect(() => {
        if (initiateComplete) {
            if (type === 4 || singleGroupLog) {
                completeGBLog({variables: {
                    groupReadingBookId: book.id
                }})
            } else {
                completeLog({variables: {
                    studentReadingBookId: book.id, 
                }})
            }
            initiateMethod(false)
        }
    }, [initiateComplete])

    const handleAdd = () => {
        Bugsnag.leaveBreadcrumb('book data sent', record, 'state')
        if (record.completed) {
            if (record.pageStart === '' || record.pageStart < 0 || isNaN(record.pageStart) || record.pageStart % 1 !== 0 || record.pageStart > 100000) {
                setRecord({...record, showError: true})
            } else {
                if (book.students && book.students.length > 0) {
                    addGroupLog({
                        variables: {
                            groupReadingBookId: book.id, 
                            pageStart: parseInt(record.pageStart),
                            pageEnd: record.pageEnd !== '' ? parseInt(record.pageEnd) : parseInt(record.pageStart) + 1,
                            entry: record.comment,
                            problemWords: record.problemWords,
                            finished: record.completed,
                            readingDate: `${record.date} ${record.time}:00`,
                            studentIds: singleGroupLog ? [student.id] : selectedStudents,
                        }})
                } else {
                addLog({
                    variables: {
                        studentReadingBookId: book.id, 
                        pageStart: parseInt(record.pageStart),
                        pageEnd: record.pageEnd !== '' ? parseInt(record.pageEnd) : parseInt(record.pageStart) + 1,
                        entry: record.comment,
                        problemWords: record.problemWords,
                        finished: record.completed,
                        readingDate: `${record.date} ${record.time}:00`,
                    }
                })
            }
            return;
            }
        }
        if (record.pageEnd === '' || record.pageStart === '' || record.pageEnd < 0 || record.pageStart < 0 || isNaN(record.pageStart) || isNaN(record.pageEnd) || record.pageStart % 1 !== 0 || record.pageEnd % 1 !== 0 || record.pageEnd > 100000 || record.pageStart > 100000) {
            setRecord({...record, showError: true})
        } else {
            if (book.students && book.students.length > 0) {
                addGroupLog({
                    variables: {
                        groupReadingBookId: book.id, 
                        pageStart: parseInt(record.pageStart),
                        pageEnd: record.pageEnd !== '' ? parseInt(record.pageEnd) : parseInt(record.pageStart) + 1,
                        entry: record.comment,
                        problemWords: record.problemWords,
                        finished: record.completed,
                        readingDate: `${record.date} ${record.time}:00`,
                        studentIds: singleGroupLog ? [student.id] : selectedStudents,
                    }})
            } else {
            addLog({
                variables: {
                    studentReadingBookId: book.id, 
                    pageStart: parseInt(record.pageStart),
                    pageEnd: parseInt(record.pageEnd),
                    entry: record.comment,
                    problemWords: record.problemWords,
                    finished: record.completed,
                    readingDate: `${record.date} ${record.time}:00`,
                }
            })
            }
        }
    }

    const getStudentIds = (students) => {
        let ids = []
        students.forEach(s => {
            ids.push(s.id)
        })
        return ids
    }
    const onSelectStudent = e => {
        if (selectedStudents.indexOf(e.target.value) !== -1) {
            setSelectedStudents(selectedStudents.filter(s => s !== e.target.value));
        } else {
            setSelectedStudents([...selectedStudents, e.target.value]);
        }
    } 

    const onDateChange = (e) => {
        const today = new Date().toISOString().split("T")[0];
        const todayAsMs = new Date(today).getTime();
        const selectedDateAsMs = new Date(e.target.value).getTime();

        if(isNaN(selectedDateAsMs)) {
            alert("Provided date is not valid");
            setRecord({...record, date: today});
            return;
        }

        if (selectedDateAsMs > todayAsMs) {
            alert("Future dates are not allowed.");
            setRecord({...record, date: today});
        } else {
            setRecord({...record, date: e.currentTarget.value});
        }
    }

    return (
        <>
            {complete &&
                <p className="u-m-base-10">{t('book_completed_you_can_still_add_logs')}</p>
            }
            <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-20 u-m-base-10')}>Reading Date &amp; Time</h2>
            <div className={styles.logReadingInputs}>
                {justNow ?
                <div className={cx(styles.logReadingField, 'u-m-right-20')}>Just Now <Icon.Edit className={cx(styles.justNow, "u-m-left-2")} onClick={() => setJustnow(false)} /></div>
                :
                <>
                <div className={cx(styles.logReadingField, 'u-m-right-20')}>
                    <Icon.Calendar className="u-m-right-10 u-text-muted" />
                    <input placeholder="5/10/25" type="date" onChange={(e) => onDateChange(e)} value={record.date} className={styles.styledInput} max={new Date().toISOString().split("T")[0]} />
                </div>
                <div className={cx(styles.logReadingField, 'u-m-right-20')}>
                    <Icon.Clock className="u-m-right-10 u-text-muted" />
                    <input placeholder="5/10/25" type="time" onChange={(e) => setRecord({...record, time: e.currentTarget.value})} value={record.time} className={styles.styledInput} />
                </div>
                <Icon.X onClick={() => setJustnow(true)} className={styles.justNow}/>
                </>
                }
            </div>
            <div className={cx(styles.logReadingField, 'u-m-top-20')}>
                    <Icon.FileText className="u-m-right-10 u-text-muted" />
                    <input type="number" inputMode="numeric" className={cx(styles.pageNum,'u-m-right-10', styles.styledInput)} placeholder="1" onChange={(e) => setRecord({...record, pageStart: e.currentTarget.value, showError: false})} value={record.pageStart} step="1" min="1" max="100000"/>
                    <span className="u-m-right-10">{t('to')}</span>
                    {!record.completed ?
                        <input type="number" inputMode="numeric" className={cx(styles.pageNum, styles.styledInput)} onChange={(e) => setRecord({...record, pageEnd: e.currentTarget.value, showError: false})} value={record.pageEnd} step="1" min="1" max="100000"/>
                        :
                        <p>The End</p>
                    }
                </div>
            <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-20')}>{t('comments')}</h2>
            <textarea className={cx(styles.logReadingComment, 'u-m-top-10 basic-form__text-box')} rows="3" placeholder="Write your comment here..." onChange={(e) => setRecord({...record, comment: e.currentTarget.value})}></textarea>
            <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-10 u-m-base-10')}>{t('any_problem_words_sounds')}</h2>
            <p className='smaller-text'>{t('type_words_sound_info')}</p>
            <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                <input
                    type="text"
                    placeholder={t('separate_words_comma_enter')}
                    className='basic-form__text-box'
                    onKeyPress={(e) => addWordTags(e)}
                    ref={tagInput}
                />
            </div>
            <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                {_.map(record.problemWords, (word, i) => {
                    return (
                        <span className={styles.logReadingProbWord} key={`probWord${i}`}>
                            {word}
                            <button className={styles.logReadingProbWordDelete} type="button" onClick={() => removeWordTag(i)}><Icon.X size="12" /></button>
                        </span>
                    )
                })} 
            </div>
            {!complete &&
                <div className={cx(styles.logReadingField, 'u-m-top-20')}>
                    <h2 className={cx(styles.logReadingSubHeader, 'u-m-right-10')}>{t('book_is_completed')}</h2>
                    <input type="checkbox" className='switchToggle' id="switch" onChange={(e) => setRecord({...record, completed: !record.completed})}  value={record.completed}/>
                    <label className={styles.switchLabel} htmlFor="switch">{t('toggle')}</label>
                </div>
            }
            {classId && book.students && book.students.length > 0 &&
                <div className="u-m-top-20">
                    <div className={styles.studentCounter}>
                        <div className={styles.studentCounterHeader} onClick={() => setExpandPupils(!expandPupils)}>
                            <h2 className={styles.logReadingSubHeader}>Add to {selectedStudents.length}/{book.students.length} pupils in this book</h2>
                            <Icon.ChevronLeft className={styles.studentCounterChevron}/>
                        </div>
                        {expandPupils &&
                            <ul>
                                {book.students.map(s => {
                                    return (
                                        <li key={`s${s.id}`} className={styles.studentCountItem}>
                                            <div className={styles.studentCounterSelect}>
                                                <input
                                                    name="student"
                                                    className="basic-form__check-box"
                                                    type="checkbox"
                                                    value={s.id}
                                                    onChange={(e) => onSelectStudent(e)}
                                                    checked={selectedStudents.indexOf(s.id) > -1}
                                                    id={`st_${s.id}`}
                                                />
                                                <label className="basic-form__check-label" htmlFor={`st_${s.id}`}>&nbsp;</label>
                                            </div>
                                            {s.firstName} {s.lastName}
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </div>
                </div>
            }
            <div className="u-m-top-20">
                {record.showError && <p className="u-m-base-5 error">
                    {!record.completed ?
                    t('make_sure_page_start_end_valid_numbers') :
                    t('make_sure_page_start_valid')
                    }
                </p>}
                {formErrors && formErrors.length > 0 && <FormErrors errors={formErrors} />}

                <Button className="u-m-right-10" onClick={() => handleAdd()} disabled={(loading || completeLoading || groupLoading) && true} disabledStyle={(record.pageEnd === '' && record.completed) ? false : record.pageEnd === '' ? true : false}>{(loading || completeLoading || groupLoading) ? t('adding') : t('add_record')}</Button>
                {complete ?
                    <Button outline onClick={() => close('HISTORY')}>{t('button.cancel')}</Button> :
                    <Button outline onClick={() => close({open: false, book: null})}>{t('button.cancel')}</Button>
                }
            </div>
            {(book.mostRecentLog && !book.completedAt) &&
                <div className={styles.sep}>
                    <h2 className={cx(styles.logReadingSubHeader, 'u-m-base-10')}>Mark As Complete (No Log)</h2>
                    <p className="u-m-top-10 u-m-base-10">This will mark the book as completed without creating a reading log.</p>
                    <Button onClick={() => toggleComplete(true)} disabled={(loading || completeLoading || completeGBLoading) && true}>{(loading || completeLoading || completeGBLoading) ? 'Saving' : 'Mark as complete'}</Button>
                </div>
            }
        </>
        )
}

export default LogNew;